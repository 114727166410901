<template>
  <div class="home">
    <div class="content-wrap">
      <div v-if="loading == false" class="invoice">

        <router-link to="/" class="logo"><img src="../../assets/images/logo.png" width="250" height="93"></router-link>

        <h3>Rückgabeprotokoll</h3>

        <h4>1. Vermieter, Mieter</h4>

        <div class="row row-gutter-20">
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Vermieter:</td>
                  <td>Standort {{ reservation.pickup_location.name }}</td>
                </tr>
                <tr>
                  <td>Adresse:</td>
                  <td>{{ reservation.pickup_location.street_name }} {{ reservation.pickup_location.street_number }}</td>
                </tr>
                <tr>
                  <td>PLZ + Ort:</td>
                  <td>{{ reservation.pickup_location.zip_code }} {{ reservation.pickup_location.city }}</td>
                </tr>
                <tr>
                  <td>Telefonnummer:</td>
                  <td>{{ reservation.pickup_location.contact_phone }}</td>
                </tr>
                <tr>
                  <td>24h Hotline:</td>
                  <td>0157 836 661 61</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ reservation.pickup_location.contact_email }}</td>
                </tr>
                <tr>
                  <td>Kundennummer:</td>
                  <td></td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Firma:</td>
                  <td>{{ reservation.company }}</td>
                </tr>
                <tr>
                  <td>Mieter:</td>
                  <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                </tr>
                <tr>
                  <td>Adresse:</td>
                  <td>{{ reservation.street_name }} {{ reservation.street_number }}</td>
                </tr>
                <tr>
                  <td>PLZ + Ort:</td>
                  <td>{{ reservation.zip_code }} {{ reservation.city }}</td>
                </tr>
                <tr>
                  <td>Geburtsdatum:</td>
                  <td>{{ reservation.birth_date }}</td>
                </tr>
                <tr>
                  <td>Telefonnummer:</td>
                  <td>{{ reservation.phone_number }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ reservation.email }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <h4>2. Mietfahrzeug, Mietdauer, Extras</h4>

        <div class="row row-gutter-20">
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Fahrzeugkategorie:</td>
                  <td>{{ reservation.category.name }}</td>
                </tr>
                <tr>
                  <td>Hersteller:</td>
                  <td>{{ reservation.make_name }}</td>
                </tr>
                <tr>
                  <td>Modell:</td>
                  <td>{{ reservation.vehicle.vehicle_model }}</td>
                </tr>
                <tr>
                  <td>Kilometerstand:</td>
                  <td>{{ reservation.vehicle.mileage }} km</td>
                </tr>
                <tr>
                  <td>Amtl. Kennzeichen:</td>
                  <td>{{ reservation.vehicle.license_plate }}</td>
                </tr>
                <tr>
                  <td>FIN:</td>
                  <td>{{ reservation.vehicle.fin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Mietbeginn:</td>
                  <td>{{ reservation.pickup_timestamp }}</td>
                </tr>
                <tr>
                  <td>Mietende:</td>
                  <td>{{ reservation.return_timestamp }}</td>
                </tr>
                <tr>
                  <td>Extra 1:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 2:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 3:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 4:</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <p>Das Fahrzeug wurde vom Vermieter im Beisein des Mieters auf Schäden untersucht. Es wurden folgende neue Schäden festgestellt:</p>

        <div style="position:relative">
          <div v-if="reservation.vehicle.group_id == 1" class="car-wrap">
            <p><img src="../../assets/handover_car.jpg" width="100%" height="auto"></p>
          </div>

          <div v-if="reservation.vehicle.group_id == 2" class="car-wrap">
            <p><img src="../../assets/handover_transporter.jpg" width="100%" height="auto"></p>
          </div>

          <div v-for="damage in vehicle_damages" v-bind:key="damage.id" style="position:absolute;left:15%;top:0;width:70%;">
            <img v-if="damage.damage_image" :src="'http://localhost:3000/'+damage.damage_image" style="max-width:100%">
          </div>
        </div>

        <p>Weitere Schäden wurden durch die Vertragsparteien nicht festgestellt.</p>

        <div class="row row-gutter-20" style="margin: 100px 0 100px 0">
          <div class="col-12">
            <p style="font-size:14px;font-weight:600;margin-bottom:80px">{{ reservation.pickup_location.name }}, den {{ reservation.return_timestamp.split(" ")[0] }}</p>
            <p style="border-bottom:2px solid #ccc;width:80%;"></p>
            <p>Standort, Datum, Mieter</p>
          </div>
          <div class="col-12">
            <p style="font-size:14px;font-weight:600;margin-bottom:80px">{{ reservation.pickup_location.name }}, den {{ reservation.return_timestamp.split(" ")[0] }}</p>
            <p style="border-bottom:2px solid #ccc;width:80%;"></p>
            <p>Standort, Datum, Vermieter</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'invoice',
  data () {
    return {
      loading: true,
      reservation: {},
      vehicle_damages: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/reservations/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.reservation = response.data.reservation;

        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.reservation.vehicle.id+'/damages', { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.vehicle_damages = response.data.vehicle_damages;
        })
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

  .invoice {
    margin: auto;
    max-width: 1200px;

    h3 {
      font-size: 28px;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 22px;
    }
  }

  .car-wrap {
    width: 70%;
    margin: auto;
  }

</style>
